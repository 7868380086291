import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, map, of, Subject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { BrandsList } from '../../shared/data/tables/brands-list';
import { environment } from '../../../environments/environment';
import { getServerErrors } from '../utils/get-server-errors';

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  private _totalRecords$ = new BehaviorSubject<number>(0);
  get totalRecords$() {
    return this._totalRecords$.asObservable();
  }
  
  
  private _busy$: BehaviorSubject<boolean>;
  private _brand$: BehaviorSubject<any[]>;
  private _error$: Subject<string | null>;
  private _deleted$: Subject<boolean | null>;
  private _submitted$: Subject<boolean | null>;
    
  get busy$() { return this._busy$.asObservable() }  
  get brand$() { return this._brand$.asObservable() }
  get error$() { return this._error$.asObservable() }
  get deleted$() { return this._deleted$.asObservable() }
  get submitted$() { return this._submitted$.asObservable() }
 
  constructor(private http: HttpClient) {    
    this._brand$ = new BehaviorSubject<any[]>([]);
    this._busy$ = new BehaviorSubject<boolean>(false);
    this._error$ = new Subject<string | null>();
    this._deleted$ = new Subject<boolean | null>();
    this._submitted$ = new Subject<boolean | null>();
    this._totalRecords$ = new BehaviorSubject<number>(0); 
  }

  load(paging?: {skip: number, take:number}) {
    this._busy$.next(true);
    const params = paging ? `?take=${paging.take}&skip=${paging.skip}` : '';
    this.http.get<any[]>(`${environment.endpoints.api}brands${params}`)
    .pipe(
      finalize(() => this._busy$.next(false))
    )
    .subscribe({
        next: (response: any) => {
          this._brand$.next(response.items),
          this._totalRecords$.next(response.totalRecords)
          const totalRecords = (response.pageCount - 1) * response.pageSize + response.items.length;
          this._totalRecords$.next(totalRecords);
        },
        error: (err:any) => {
          const error: string = getServerErrors(err);
          this._error$.next(error);
        },  
      }
    );
  }

  delete(item){
    this._busy$.next(true);
    this.http.delete<any>(`${environment.endpoints.api}brands/${item.id}`)
    .pipe(
      finalize(() => this._busy$.next(false))
    )
    .subscribe({
      next: (response: any) => {
        this._deleted$.next(true);
        this.load();        
      },
      error: (err:any) => {
        const error: string = getServerErrors(err);
        this._error$.next(error);
      },  
      }
    );
  }

  upload(file:File){
    this._busy$.next(true);
    this.http.post<BrandsList[]>(`${environment.endpoints.api}file`, {file:file})
    .pipe(
      finalize(() => this._busy$.next(false))
    )
    .subscribe({
      next: (response: any) => {
        this._submitted$.next(true);
        this._brand$.next(response);        
      },
      error: (err:any) => {
        const error: string = getServerErrors(err);
        this._error$.next(error);
      },           
      }
    );
  }

  save(form){
    this._busy$.next(true);
    this.http.post<any>(`${environment.endpoints.api}brands`, form)
    .pipe(
      finalize(() => this._busy$.next(false))
    )
    .subscribe({
      next: (response: any) => {
        this._submitted$.next(true);
        this.load();      
      },
      error: (err:any) => {
        const error: string = getServerErrors(err);
        this._error$.next(error);
      },      
      }
    );
  }

  update(form){
    this._busy$.next(true);
    this.http.patch<any>(`${environment.endpoints.api}brands/${form.id}`, form)
    .pipe(
      finalize(() => this._busy$.next(false))
    )
    .subscribe({
      next: (response: any) => {
        this._submitted$.next(true);
        this.load();    
      },
      error: (err:any) => {
        const error: string = getServerErrors(err);
        this._error$.next(error);
      },            
      }
    );
  }

  destroy(){   
    this._busy$.complete();
    this._brand$.complete();
  }
}