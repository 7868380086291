import { Component, OnInit, Input, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import {DrawModalContent} from './modal/draw-modal.component';
import {Router, ActivatedRoute} from '@angular/router';
import { Observable, Subscription, Subject, takeUntil } from 'rxjs';
import {ProjectService} from '../../shared/services/project.service';
import {DrawService} from '../../shared/services/draw.service';
import { ToastrService } from 'ngx-toastr';
import {ColumnMode} from '@swimlane/ngx-datatable';
import { ColorService } from '../../shared/services/theme.service';
import {NavService} from '../../shared/services/nav.service';
import { MatDialog } from '@angular/material/dialog';
import { StandardMessageDrawToolComponent } from '../../../app/standard-message-draw-tool/standard-message-draw-tool.component'; 
import { ManageWinnersComponent } from '../manage-winners/manage-winners.component';
@Component({
  selector: 'app-draw-tool',
  templateUrl: './draw-tool.component.html',
  styleUrls: ['./draw-tool.component.scss']
}) 
export class DrawToolComponent implements OnInit {
  isLoading = false; 
  selectedDrawName: string | null = null;
  selectedDrawId: string | null | undefined;
  showManageWinners: boolean = false; 
  selectedDrawEntries : number | null | undefined;
  selectedDrawConfig :string | null | undefined;  
  @ViewChild('manageWinnersContainer', { read: ViewContainerRef }) manageWinnersContainer: ViewContainerRef;
  @Input() project;
  @Input() token;
  private _id;
  destroy$: Subject<boolean> = new Subject()
  drawListItem$: Observable<any[]>;
  drawListLoading$: Observable<boolean>;
  error$:Observable<any>;
  ColumnMode = ColumnMode
  colorScheme: string;
  hasData: boolean = false; 
  private date;

  constructor(public modalService: NgbModal,
              private route:ActivatedRoute, 
              private projectService:ProjectService,
              private drawService:DrawService,
              private toastrService: ToastrService,
              private colorService: ColorService,
              private tabService: NavService,
              public dialog: MatDialog 
            ) {
    this._id = this.route.snapshot.paramMap.get('id');
    this.drawListItem$ = this.projectService.drawList$;   
    this.drawListLoading$ = this.projectService.drawsLoading$;
    this.error$ = this.projectService.error$;
   }

  onDraw(brand){
      const dialogRef = this.modalService.open(DrawModalContent, {
    size: 'lg'
  });
    dialogRef.componentInstance.id = this._id;
    dialogRef.componentInstance.projdate = this.project;
  }
  

  
  getProjectDetails(id: string): void {
    // this.isLoading = true; 
    this.colorService.getProjectById(id).subscribe(
      (projectDetails) => {
        console.log('Project details:', projectDetails);
        this.colorScheme = projectDetails.colorScheme;
        this.date = projectDetails;
        // this.isLoading = false; 
      },
      (error) => {
        console.error('Error fetching project details:', error);
        // this.isLoading = false; 
      }
    );
  }

  ngOnInit() { 
    if (this._id) {
      this.projectService.drawList(this._id); // Trigger the data fetching
    }
    this.route.params.subscribe(params => {
      const id = params['id']; 
      console.log('Project ID:', id); 
      this.getProjectDetails(id);
    });

    this.colorService.currentColorScheme.subscribe(color => {
      console.log("inside")
      console.log(color)
      this.colorScheme = color;
    });
    this.drawListItem$.pipe(takeUntil(this.destroy$)).subscribe();

    this.error$.pipe(takeUntil(this.destroy$)).subscribe(err => {
      if(!err) return;
      this.toastrService.error(err, undefined, { timeOut: 3000, positionClass: 'toast-bottom-right'});
    })
    // this.loadDraws();
    this.projectService.drawList(this._id)
    // this.loadData() 
    this.drawListItem$.subscribe(data => {
      this.hasData = data.length > 0; 
    });
    
  } 

//   download(draw) {
//     const drawName = draw && draw.name ? draw.name : "All draws";
//     let _config = {
//       "type": "draws",
//       "configuration": draw ? { draw: draw.id } : "", 
//       "name": drawName,
//       "description": ""
//     };
//     this.projectService.export(_config, this._id);
//     this. showExportMessage();
//     // this.toastrService.info(`Draw ${drawName} queued for export`, undefined, { timeOut: 3000, positionClass: 'toast-bottom-right'});
// }

  performDraw(draw){

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  showExportMessage(): void {
    this.dialog.open(StandardMessageDrawToolComponent, {
      data: { message: 'Export initiated!' }
    });
  }
  
  toggleManageWinners(draw: any): void {
    this.selectedDrawId = draw.id;
    this.selectedDrawName = draw.name;
    this.selectedDrawEntries = draw.configuration.numberOfEntries;
    this.selectedDrawConfig = draw.configuration;  
    this.showManageWinners = !this.showManageWinners;
  }
  toggleManageWinner(): void {
    this.showManageWinners = !this.showManageWinners;
  }
  
  
}