import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, tap, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ColorService {
  private baseUrl = `${environment.endpoints.api}projects`;
  private defaultColor = '#054f7f'; // Default color, blue
  private colorScheme = new BehaviorSubject<string>(localStorage.getItem('selectedColorScheme') || this.defaultColor);

  currentColorScheme = this.colorScheme.asObservable();

  constructor(private http: HttpClient) { }
  ngOninit(){
   this.testGetProjectById()
  }


  
  getProjectById(id: string): Observable<{ userId: string, colorScheme: string }> {
    return this.http.get(`${this.baseUrl}/${id}`).pipe(
      map((data: any) => {
        console.log('User ID:', id);
        console.log('Response data:', data ); 
        if (data && data.configuration && data.configuration.dashboard && data.configuration.dashboard.colorScheme) {
          console.log('Color scheme:', data.configuration.dashboard.colorScheme); // Logging the color scheme
          return {
            userId: data.id,
            colorScheme: data.configuration.dashboard.colorScheme
          };
        } else {
          throw new Error('Color scheme not found in response data.');
        }
      }),
      catchError((error) => {
        console.error('Error fetching project:', error);
        return throwError('Failed to fetch project.');
      })
    );
}


  changeColorScheme(color: string) {
    console.log('Setting color:', color);
    this.colorScheme.next(color);
  }

  testGetProjectById() {
    const testId = 'known-good-id';
    this.getProjectById(testId).subscribe({
      next: (project) => console.log('Test project data:', project),
      error: (error) => console.error('Test project fetch error:', error)
    });
  }


  chartTheme(color:string){
    let _pallette;
    switch(color){
      case "#1c771e":
        _pallette = ['#1C771E', '#4CAF50', '#1976D2', '#2196F3', '#424242','#9E9E9E','#FF9800','#FFEB3B'];
        break;
        case '#494f53':
          _pallette = ['#494F53', '#90CAF9', '#1B5E20', '#4CAF50', '#424242','#9E9E9E','#FF9800','#FFEB3B'];
        break;
        case '#9b1515':
          _pallette = ['#9B1515', '#FF5F52', '#1976D2', '#2196F3', '#424242','#9E9E9E','#FF9800','#FFEB3B'];
        break;
        case '#054f7f':
          _pallette = ['#054F7F', '#42A5F5', '#1B5E20', '#4CAF50', '#424242','#9E9E9E','#FF9800','#FFEB3B'];
        break;
      default:
        _pallette = ['#054F7F', '#42A5F5', '#1B5E20', '#4CAF50', '#424242','#9E9E9E','#FF9800','#FFEB3B'];
        break;
    }
    return _pallette;
  }

  
}

