import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import {DrawModalContent} from './modal/draw-modal.component';
import {Router, ActivatedRoute} from '@angular/router';
import { Observable, Subscription, Subject, takeUntil } from 'rxjs';
import {ProjectService} from '../../shared/services/project.service';
import {IntegrationsService} from '../../shared/services/integrations.service';
import {DrawService} from '../../shared/services/draw.service';
import { ToastrService } from 'ngx-toastr';
import { withRequestsMadeViaParent } from '@angular/common/http';
import {ColumnMode} from '@swimlane/ngx-datatable';
import { ColorService } from '../../shared/services/theme.service';
@Component({
  selector: 'app-exports',
  templateUrl: './exports.component.html',
  styleUrls: ['./exports.component.scss']
})
export class ExportsComponent implements OnInit {
  private _id;
  exportConfig: any = {};
  destroy$: Subject<boolean> = new Subject()
  exportListItem$: Observable<any[]>;
  exportListLoading$: Observable<boolean>;
  error$:Observable<any>;
  colorScheme: string;
  
  ColumnMode = ColumnMode

  constructor(public modalService: NgbModal,
              private route:ActivatedRoute, 
              private projectService:ProjectService,
              private drawService:DrawService,   
              private toastrService: ToastrService,
              private integrationsService:IntegrationsService,
              private colorService: ColorService,
            ) {
    this._id = this.route.snapshot.paramMap.get('id');
    this.exportListItem$ = this.projectService.exportList$;   
    this.exportListLoading$ = this.projectService.exportLoading$;
    this.error$ = this.projectService.error$;
   }

  onDraw(brand){
    const dialogRef = this.modalService.open(DrawModalContent);
    // dialogRef.componentInstance.data = brand;
    dialogRef.componentInstance.id = this._id;
  }
 
  ngOnInit() { 
    this.route.params.subscribe(params => {
      const id = params['id']; 
      console.log('Project ID:', id); 
      this.getProjectDetails(id);
    });

    this.exportListItem$.pipe(takeUntil(this.destroy$)).subscribe();

    this.error$.pipe(takeUntil(this.destroy$)).subscribe(err => {
      if(!err) return;
      this.toastrService.error(err, undefined, { timeOut: 3000, positionClass: 'toast-bottom-right'});
    })

    this.projectService.exportList(this._id)
  } 

  getProjectDetails(id: string): void {
    this.colorService.getProjectById(id).subscribe(
      (projectDetails) => {
        console.log('Project details:', projectDetails);
        this.colorScheme = projectDetails.colorScheme;
      },
      (error) => {
        console.error('Error fetching project details:', error);
      }
    );
  }

  download(){
    let _config = {
      "type": "draw",
      "configuration": "",
      "name": "test",
      "description": ""
    }
    this.projectService.export(_config,this._id);
  }

  downloadExport(item){
    this.projectService.downloadExport(item)
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
