
<div class="control-panel left" *ngIf="selection.hasValue()" style="float:left; padding: 8px; display: flex; align-items: center;">
  <div class="popup-content">
    <div class="selected-count" style="margin-top: 10px;">
      <input type="checkbox" [(ngModel)]="isDeselectAllChecked" (change)="deselectAll($event)">
      {{ selectedIds.size }} selected
  </div>
  
      <div class="vertical-divider"></div>
      <div style="margin-top: 10px; ">
          <mat-label style="font-size: 16px;">Update Status: </mat-label>
          <mat-select style="border: none; border-bottom: 1px dotted; border-radius: 2px;" [(ngModel)]="selectedStatus" name="moderationStatus" class="status-select">
            <mat-option *ngFor="let status of moderationStatusOptions" [value]="status">
                {{ status | lowercase | titlecase }}
            </mat-option>
        </mat-select>
        
      </div>
      <div class="vertical-divider"></div>
      <div class="d-flex justify-content-end align-items-center" style="margin-top: 10px;">
        <button 
        (click)="confirmUpdate();" 
        style="margin-bottom: 10px; border-radius: 0px; color: white;"
        type="button" 
        class="btn me-1 view-data-button"
        [disabled]="!selectedStatus"
        [ngStyle]="{'background-color': selectedStatus ? colorScheme : '#d3d3d3'}">
        Confirm
      </button>
          <button (click)="deselectAll($event)" style="background-color: #ffffff; color: grey; margin-bottom: 10px; border: none;" type="button" class="btn me-1 view-data-button" [disabled]="!selectedStatus">
              Cancel
          </button>
      </div>
  </div>
  <button class="action-button" 
  class="btn me-1 view-data-button"
  style="display: flex; align-items: center; justify-content: center; width: 135px; border-radius: 2px;"
  (click)="exportAllEntries()"
  [ngStyle]="{'background-color': colorScheme}">
<mat-icon class="export-icon">download</mat-icon> 
<span style="margin-left: 5px; color: white;">Export</span>
</button>
</div>

  
<div class="spinner-container" *ngIf="isLoading">
  <mat-spinner  [diameter]="30" ></mat-spinner>
</div>

<div class="control-panel " *ngIf="!selection.hasValue()">
<div class="left-controls">
<div >

<mat-select  [placeholder]="selectedModerationStatus ? 'Status: ' + selectedModerationStatus : 'Status: All'" style="height: 35px; padding-bottom: 30px; margin-top: 10px; border-radius: 0px;" [(ngModel)]="selectedModerationStatus" (ngModelChange)="applyFilters()" class="status-select" #select>
  <mat-option value="all">All</mat-option> 
  <ng-container>
    <mat-option  *ngFor="let status of moderationStatusOptions"  [value]="status">
      <span *ngIf="!select.panelOpen" >Status:</span> <b></b> {{ status === 'all' ? 'All' : status }}
    </mat-option>
  </ng-container>
</mat-select>
</div>
<div class="vertical-divider" ></div>
<div class="search-container">
<i class="search-icon fas fa-search" style="color: black;"></i>
<input matInput class="search-box" [(ngModel)]="searchText" #searchInput (ngModelChange)="applyFilters()"  placeholder="Search by Mobile Number">
</div>
</div> 
<button class="action-button" 
  class="btn me-1 view-data-button"
  style="display: flex; align-items: center; justify-content: center; border-radius: 2px;"
  (click)="download();"
  [ngStyle]="{'background-color': colorScheme}">
<mat-icon class="export-icon">download</mat-icon> 
<span style="margin-left: 5px; color: white;">Export</span>
</button>

</div>

<table  style="border-top: none;" mat-table matSort   [dataSource]="dataSource" class="mat-elevation-z8" >

<!-- Checkbox Column -->
<ng-container matColumnDef="select" >
<th style="border-bottom: none;background-color: #f5f5f5; border-left:none ;" mat-header-cell *matHeaderCellDef class="table-headers">
<input type="checkbox" (change)="$event ? masterToggle() : null"
[checked]="isAllSelected()"
[indeterminate]="selection.hasValue() && !isAllSelected()"
              [ngStyle]="{'color': colorScheme}"
              [(ngModel)]="checkbox">
</th>
<td mat-cell *matCellDef="let row" style="margin: 50px" (click)="toggleRowSelection($event, row)">
<input type="checkbox" (click)="$event.stopPropagation()"
(change)="$event ? toggleSelection(row) : null"
[checked]="selection.isSelected(row)"
[ngStyle]="{'color': colorScheme, 'cursor': row.moderationStatus === 'blacklisted' ? 'not-allowed' : 'pointer'}"
[disabled]="row.moderationStatus === 'blacklisted'">
</td>
</ng-container>

<!-- Viewd Data Column -->
<!-- Raw Meta Column (Text only) -->
<!-- <ng-container matColumnDef="rawMeta">
<th mat-header-cell *matHeaderCellDef class="table-headers">Raw Meta</th>
<td mat-cell *matCellDef="let entry" style="text-align: right;">Raw Meta</td>
</ng-container> -->

<!-- Action Column (View Data Button) -->
<!-- <ng-container matColumnDef="action">
<th mat-header-cell *matHeaderCellDef class="table-headers" style=" background-color: #f5f5f5">Raw Meta</th>
<td mat-cell *matCellDef="let entry">
<button (click)="viewEntry(entry)"  style="color: white;" mat-button [ngStyle]="{'background-color': colorScheme}" class="view-data-button btn me-1 view-data-button">
<mat-icon style="color: white;">data_object</mat-icon>
View Data
</button>
</td>
</ng-container> -->


<!-- Image Column -->
<ng-container matColumnDef="image">
  <th class="table-headers" style="border-bottom: none; border-top: none; background-color: #f5f5f5" mat-header-cell *matHeaderCellDef> 
    Entry Info 
  </th>
  <td mat-cell *matCellDef="let entry" (click)="$event.stopPropagation()">
    <ng-container *ngIf="entry.entryType === 'code'; else otherEntries">
      <!-- Code Entry -->
      <div class="code-container" style="cursor: pointer;">
        <img class="code-image" src="../../../assets/images/papertrail/bottleTop.jpg" alt="Code Image"
        (click)="CodeImage( entry.payload.Code,entry.payload.Status,entry.payload.BankName,entry.consumer.firstName, entry.consumer.createdAt, entry.moderationStatus, entry.consumer.mobileNumber, entry.id)" />
        <div (click)="CodeImage( entry.payload.Code,entry.payload.Status,entry.payload.BankName,entry.consumer.firstName, entry.consumer.createdAt, entry.moderationStatus, entry.consumer.mobileNumber, entry.id)" class="code-text">{{ entry.payload.Code }}</div>
      </div>
    </ng-container>
    <ng-template #otherEntries>
      <!-- Handling different types of entries -->
      <ng-container *ngIf="entry.entryType === 'image' || entry.entryType === 'till-slip'; else videoCheck">
        <!-- Image Entry -->
        <img class="image-url" style="width: 50px; height: 70px;"
          [src]="entry.payload.imageurl || entry.payload.imageUrl ||'https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png'"
          alt="Entry Image" (click)="(entry.payload.imageUrl || entry.payload.imageurl) ? showLargeImage(entry.payload.imageUrl || entry.payload.imageurl, entry.consumer.firstName, entry.consumer.createdAt, entry.consumer.mobileNumber, entry.moderationStatus, entry.reward?.name,entry.voucher, entry.reward?.value, entry.id, entry.entryStatus, entry.payload.videoUrl, entry.payload.audioUrl, entry.payload.Text,entry.entryType) : null; $event.stopPropagation()">

      </ng-container>
      
      <ng-template #videoCheck >
        <!-- Video Entry -->
          <ng-container *ngIf="entry.entryType === 'video'; else audioCheck">
            <div >
              <img class="image-url" style="border: 1px solid black;" src="../../../assets/images/papertrail/3videoIcon.jpg" alt="Video Entry"
              (click)=" showLargeImage(entry.payload.imageUrl || entry.payload.imageurl, entry.consumer.firstName, entry.consumer.createdAt, entry.consumer.mobileNumber, entry.moderationStatus, entry.reward?.name,entry.voucher, entry.reward?.value, entry.id, entry.entryStatus, entry.payload.videoUrl, entry.payload.audioUrl, entry.payload.Text,entry.entryType) ; $event.stopPropagation()" style="cursor: pointer; width: 50px; height: 50px;">
            </div>
         
          </ng-container>
      </ng-template>
      
      <ng-template #audioCheck>
        <ng-container *ngIf="entry.entryType === 'audio'; else textCheck">
          <!-- Audio Entry -->
          <img class="image-url" src="../../../assets/images/papertrail/1audioIcon.jpg" alt="Audio Entry"
               (click)=" showLargeImage(entry.payload.imageUrl || entry.payload.imageurl, entry.consumer.firstName, entry.consumer.createdAt, entry.consumer.mobileNumber, entry.moderationStatus, entry.reward?.name,entry.voucher, entry.reward?.value, entry.id, entry.entryStatus, entry.payload.videoUrl, entry.payload.audioUrl, entry.payload.Text, entry.entryType) ; $event.stopPropagation()" style="cursor: pointer; width: 50px; height: 50px;">
        </ng-container>
      </ng-template>
      
      <ng-template #textCheck>
        <ng-container *ngIf="entry.entryType === 'text'">
          <!-- Text Entry -->
          <img class="image-url" src="../../../assets/images/papertrail/1textIcon.jpg" alt="Text Entry"
               (click)=" showLargeImage(entry.payload.imageUrl || entry.payload.imageurl, entry.consumer.firstName, entry.consumer.createdAt, entry.consumer.mobileNumber, entry.moderationStatus, entry.reward?.name,entry.voucher, entry.reward?.value, entry.id, entry.entryStatus, entry.payload.videoUrl, entry.payload.audioUrl, entry.payload.Text,entry.entryType) ; $event.stopPropagation()" style="cursor: pointer; width: 50px; height: 50px;">
        </ng-container>
      </ng-template>
      
    </ng-template>
  </td>
</ng-container>



<!-- Date Column -->
<ng-container matColumnDef="date" style="justify-content: center;"> 
<th class="table-headers" style="border-bottom: none; background-color: #f5f5f5" mat-header-cell *matHeaderCellDef [mat-sort-header]="'createdAt'">Date</th>
<td mat-cell *matCellDef="let entry" (click)="$event.stopPropagation()">
<div>{{ entry.createdAt | date:'dd MMMM yyyy' }}</div>
<div class="small-time">{{ entry.createdAt | date:'hh:mm a' }}</div>
</td>
</ng-container>


<!-- First Name Column -->
<!-- <ng-container matColumnDef="firstName"> {{entry | json}}
<th class="table-headers" style="border-bottom: none; background-color: #f5f5f5" mat-header-cell *matHeaderCellDef> First Name </th>
<td mat-cell *matCellDef="let entry"  (click)="$event.stopPropagation()"> {{ entry.consumer.firstName }} </td>
</ng-container> -->

<!-- last Name Column -->
<!-- <ng-container matColumnDef="lastName">
<th class="table-headers" style="border-bottom: none; background-color: #f5f5f5" mat-header-cell *matHeaderCellDef> Last Name </th>
<td mat-cell *matCellDef="let entry"  (click)="$event.stopPropagation()"> {{ entry.consumer.lastName }} </td>
</ng-container> -->

<!-- Prize  Column -->
<ng-container matColumnDef="prize">
  <th class="table-headers" style="border-bottom: none; background-color: #f5f5f5" mat-header-cell *matHeaderCellDef> Prize  </th>
  <td mat-cell *matCellDef="let entry"  (click)="$event.stopPropagation()"> {{ entry.reward?.name ? entry.reward.name : '-'  }} </td>
</ng-container>
     <!-- value  Column -->
     <ng-container matColumnDef="value">
      <th class="table-headers" style="border-bottom: none; background-color: #f5f5f5" mat-header-cell *matHeaderCellDef> Value </th>
      <td mat-cell *matCellDef="let entry" (click)="$event.stopPropagation()">
        {{ entry.reward?.value ? entry.reward.value : '-' }}
      </td>
    </ng-container>
    
<!-- Status Column -->
<ng-container matColumnDef="moderationStatus">
  <th class="table-headers" style="border-bottom: none; background-color: #f5f5f5" mat-header-cell *matHeaderCellDef> Moderation Status </th>
  <td style="border-right: 1px solid rgb(216, 213, 213);" mat-cell *matCellDef="let entry" class="status-cell" (click)="$event.stopPropagation()">
      <!-- Only display icons for statuses other than 'pending' -->
      <mat-icon *ngIf="entry.moderationStatus !== 'pending'" class="status-icon" [ngClass]="{
              'blacklisted': entry.moderationStatus === 'blacklisted',
              'invalid': entry.moderationStatus === 'rejected',
              'valid': entry.moderationStatus === 'accepted'
          }">fiber_manual_record</mat-icon>
      
      <!-- Show the status text, excluding 'pending' -->
      <span *ngIf="entry.moderationStatus !== 'pending'">
          {{ entry.moderationStatus | lowercase | titlecase }}
      </span>
  </td>
</ng-container>



<!-- Mobile Number Column -->
<ng-container matColumnDef="mobileNumber" style="border-right: 2px solid red;">
  <th class="table-headers" style="border-bottom: none; background-color: #f5f5f5" mat-header-cell *matHeaderCellDef> Mobile Number </th>
  <td style="border-right: 1px solid rgb(216, 213, 213);" mat-cell *matCellDef="let entry" (click)="$event.stopPropagation()">
    <div style="display: flex; align-items: center;">
      <span>{{ entry.consumer.mobileNumber }}</span>
      <mat-icon *ngIf="entry.moderationStatus === 'blacklisted'" class="status-icon blacklisted" style="margin-left: 8px;">block</mat-icon>
    </div>
    <div class="small-name">{{ entry.consumer.firstName }} {{ entry.consumer.lastName }}</div>
  </td>
</ng-container>

<ng-container matColumnDef="Region" style="border-right: 2px solid red;">
  <th class="table-headers" style="border-bottom: none; background-color: #f5f5f5" mat-header-cell *matHeaderCellDef> Region</th>
  <td style="border-right: 1px solid rgb(216, 213, 213);" mat-cell *matCellDef="let entry" (click)="$event.stopPropagation()">
    <div style="display: flex; align-items: center;">
      <span *ngIf="entry.consumer.region; else noRegion">{{ entry.consumer.region }}</span>
      <ng-template #noRegion>-</ng-template>
    </div>
  </td>
</ng-container>

    

     <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let entry; columns: displayedColumns;"
    (click)="toggleRowSelection($event, entry)">
  </tr>
 </table>
<div>

<mat-paginator [length]="totalRecords"
        [pageSize]="10"
        [pageSizeOptions]="[10, 25, 50, 100]"
        aria-label="Select page"
        (page)="onPageChange($event)"
        showFirstLastButtons>
</mat-paginator>
</div>